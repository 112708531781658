import React, { useState, useEffect } from "react"

import LayoutOB from "../../components/layoutob.js"
import Seo from "../../components/seo"
import { useLocation } from "@reach/router"

import FormSignup from "../../components/Form/signup/FormSignup"
import { deDE } from "../../lang/de-DE"
import { deCH } from "../../lang/de-CH"

export default function CheckoutPage() {
  const location = useLocation()
  var searchParams = new URLSearchParams(location.search)
  var countryParam = searchParams.get("country")
  const [country, setCountry] = useState(countryParam)
  let txt = ""

  if (txt === "") {
    if (countryParam === "DE"||countryParam === "de") {
      txt = deDE.Checkout
    } else {
      txt = deCH.Checkout
    }
  }

  const handleUpdateCountry = input => {
    setCountry(input)
  }

  useEffect(() => {
    if (country === "DE"||countryParam === "de") {
      txt = deDE.Checkout
    } else {
      txt = deCH.Checkout
    }
  }, [country])

  return (
    <LayoutOB location="CH" language="de-CH">
      <Seo title="DoryGo - Checkout" description="Checkout" lang="de-CH" />
      <FormSignup
        country={country}
        txt={txt}
        handleUpdateCountry={handleUpdateCountry}
        language="de-CH"
      />
    </LayoutOB>
  )
}